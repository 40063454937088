<template>
  <div class="flex flex-col h-full pb-4 overflow-y-auto text-xs bg-gray-800">
    <div
      class="pt-3 text-xs bg-gray-700 border-b-2 border-gray-800 tabs sub-tabs"
    >
      <template v-for="tab in tabs">
        <a
          href="#"
          class="tab"
          :class="{ 'font-bold bg-gray-800 rounded-t-sm': active === tab.type }"
          @click.prevent="active = tab.type"
        >
          <span class="text-gray-100">{{ tab.label }}</span>
        </a>
      </template>
    </div>

    <div class="flex-1">
        <SettingsTab
          v-if="active === 'settings'"
          :viewer="viewer.core"
          :sequence="{}"
          @settings-changed="$emit('settings-changed', $event)"
        />
        <SceneTab 
          v-if="active === 'scene'"
          :viewer="viewer.core"
          :sequence="{}"
          @settings-changed="$emit('settings-changed', $event)"
        />
      <iframe
        id="drive"
        v-show="active === 'content'"
        class="w-full h-full"
        :src="driveTabUrl"
      />
    </div>

  </div>
</template>
<script>
import iframeCommunication from '@mixins/iframe-communication-mixin.js'

const ItemInspector = () =>
  import('@components/editor/scene/panels/ItemInspector')
// const SceneTree = () => import('@components/editor/scene/panels/SceneTree')
// const SettingsTab = () => import('@components/editor/scene/panels/SettingsTab')
const SettingsTab = () => import('@components/editor/scene/panels/SettingsTab')
const SceneTab = () => import('@components/editor/scene/panels/SceneTab')

export default {
  name: 'ResourcesTree',

  mixins: [iframeCommunication],

  components: {
    ItemInspector,
    // SceneTree,
    SceneTab,
    SettingsTab
  },

  props: {
    viewer: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      active: null,

      tabs: [
        { type: 'content', label: 'Drive' },
        // { type: 'scene', label: 'Scene' },
        { type: 'settings', label: 'Settings' },
        { type: 'scene', label: 'Scene' }
      ]
    }
  },

  mounted() {
    this.active = 'content'
  }
}
</script>
